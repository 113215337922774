import React, { useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import { Form, PrimaryButton, FieldTextInput } from '../../../components';
import css from './InquiryModal.module.css';
import basedPostUrlRequestLogedIn from '../../../util/apiRequests/basedPostUrlRequestLogedIn';

const InquiryModal = ({ isOpen, onClose, onCloseInquirySend }) => {
  const [statusMessage, setStatusMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isError, setIsError] = useState(false);
  const [hideForm, setHideForm] = useState(false);
  const intl = useIntl();

  const onSubmit = async values => {
    console.log('values:', values);
    try {
      const response = await basedPostUrlRequestLogedIn('/api/inquiry', values);
      onCloseInquirySend();
      if (response && !response.error) {
        setSuccessMessage(intl.formatMessage({ id: 'InquiryModal.successMessage' }));
        setIsError(false);
        setHideForm(true);

        setTimeout(() => {
          onClose();
        }, 10000);
      } else {
        const errorMessage =
          response?.message || intl.formatMessage({ id: 'InquiryModal.errorMessage' });
        setStatusMessage(errorMessage);
        setIsError(true);
      }
    } catch (error) {
      console.error('Error sending inquiry:', error);
      setStatusMessage(intl.formatMessage({ id: 'InquiryModal.unexpectedError' }));
      setIsError(true);
    }
  };

  const validate = values => {
    const errors = {};
    if (!values.topic) {
      errors.topic = intl.formatMessage({ id: 'InquiryModal.topicRequired' });
    }
    if (!values.email) {
      errors.email = intl.formatMessage({ id: 'InquiryModal.emailRequired' });
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = intl.formatMessage({ id: 'InquiryModal.invalidEmail' });
    }
    if (!values.message) {
      errors.message = intl.formatMessage({ id: 'InquiryModal.messageRequired' });
    }
    return errors;
  };

  if (!isOpen) return null;

  const modalClasses = classNames(css.modalBackdrop, css.open);

  return (
    <div className={modalClasses}>
      <div className={css.modalContent}>
        <button className={css.closeButton} onClick={onClose}>
          &times;
        </button>
        <h2>
          <FormattedMessage id="InquiryModal.heading" />
        </h2>
        <FinalForm
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, submitting }) => (
            <Form className={css.form} onSubmit={handleSubmit}>
              {!hideForm && (
                <>
                  <FieldTextInput
                    className={css.field}
                    type="text"
                    id="topic"
                    name="topic"
                    label={<FormattedMessage id="InquiryModal.topicLabel" />}
                    placeholder={intl.formatMessage({ id: 'InquiryModal.topicLabel' })}
                  />
                  <FieldTextInput
                    className={css.field}
                    type="email"
                    id="email"
                    name="email"
                    label={<FormattedMessage id="InquiryModal.emailLabel" />}
                    placeholder={intl.formatMessage({ id: 'InquiryModal.emailLabel' })}
                  />
                  <FieldTextInput
                    className={css.field}
                    type="textarea"
                    id="message"
                    name="message"
                    label={<FormattedMessage id="InquiryModal.messageLabel" />}
                    placeholder={intl.formatMessage({ id: 'InquiryModal.messageLabel' })}
                  />
                  <div className={css.checkboxContainer}>
                    <label className={css.checkboxLabel}>
                      <Field
                        name="subscribe"
                        component="input"
                        type="checkbox"
                        className={css.checkbox}
                      />
                      <FormattedMessage id="InquiryModal.subscribeLabel" />
                    </label>
                  </div>
                  <PrimaryButton
                    type="submit"
                    className={css.submitButton}
                    inProgress={submitting}
                    disabled={submitting}
                  >
                    <FormattedMessage id="InquiryModal.submitButton" />
                  </PrimaryButton>
                  <p className={css.smallText}>
                    <FormattedMessage id="InquiryModal.smallText" />
                  </p>
                </>
              )}
              {successMessage && (
                <p className={classNames(css.statusMessage, css.successMessage)}>
                  {successMessage}
                </p>
              )}
              {statusMessage && (
                <p
                  className={classNames(css.statusMessage, {
                    [css.errorMessage]: isError,
                    [css.successMessage]: !isError,
                  })}
                >
                  {statusMessage}
                </p>
              )}
            </Form>
          )}
        />
      </div>
    </div>
  );
};

export default InquiryModal;
